import React from 'react';
import {Link} from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <a href="/#serviceDeals">Deals</a>
                </li>
                <li>
                    <a href="/#eventService">Événements</a>
                </li>
                <li>
                    <a href="/#serviceTravel">Covoiturages</a>
                </li>
                
                <li>
                    <a href="/#actualiteIdent">Actualités</a>
                </li>
                <li>
                    <a href="/#fun-fact-area-shouzpay">ShouzPay</a>
                </li>
                <li>
                    <Link to="/TikTok-Award">TikTok Award</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
