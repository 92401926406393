import React from 'react';
import featureThumb from '../../assets/images/actualite_illustration.png';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function FeaturesHomeTwo() {
    return (
        <>
            <section className="appie-features-area-2 pt-90 pb-100" id="actualiteIdent">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title appie-section-title-2 text-center">
                                <h3 className="appie-title">
                                    Dans la rubrique Actualité
                                </h3>
                                <p>Nous mettons à votre disposition un pôle d'informations provenant de source sûres, et vous êtes au parfum des dernières opportunités du secteur.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="appie-features-box-item">
                                    <h4 className="title">Exclusivité</h4>
                                    <p>Nous vous informons en temps réel des actualités et scoop d'ici et ailleurs</p>
                                </div>
                                <div className="appie-features-box-item item-2">
                                    <h4 className="title">Crédibilité</h4>
                                    <p>Notre équipe s'occupe de démêler les fake news afin de ne vous envoyer que les informations avérées.</p>
                                </div>
                                <div className="appie-features-box-item item-3">
                                    <h4 className="title">Appel d'offre & Offre d'emploi</h4>
                                    <p>Nous vous envoyons les dernières opportunités en fonction de vos centres d'intérêts</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-features-thumb wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={featureThumb} className="shadow-lg img-border-radius" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features-shape-1">
                    <img src={shape15} alt="" />
                </div>
                <div className="features-shape-2">
                    <img src={shape14} alt="" />
                </div>
                <div className="features-shape-3">
                    <img src={shape13} alt="" />
                </div>
            </section>
        </>
    );
}

export default FeaturesHomeTwo;
