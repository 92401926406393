import React from 'react';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

function FooterHomeOne({ className }) {
    const years = new Date().getFullYear();
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="/">
                                        <img src={logo} className="logo icon-logo2" alt="logo" />
                                    </a>
                                </div>
                                
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://www.linkedin.com/company/shouz225" target='_blank' rel="noreferrer">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/shouz225" target='_blank' rel="noreferrer">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/Shouz225" target='_blank' rel="noreferrer">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://t.me/shouz_community" target='_blank' rel="noreferrer">
                                                <i className="fab fa-telegram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/shouz_corporation/" target='_blank' rel="noreferrer">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Développeur</h4>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Entreprise</h4>
                                <ul>
                                   
                                    <li>
                                        <a href="#">Resources</a>
                                    </li>
                                    
                                    <li>
                                        <Link to="/confidential-politic">Politique de confidentialité</Link>
                                    </li>
                                    <li>
                                        <Link to="/condition-general">Condition générale d'utilisation</Link>
                                    </li>
                                    <li>
                                        <a href="#">Evolutions</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Contact</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:shouz225@outlook.com">
                                            <i className="fal fa-envelope" /> shouz225@outlook.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="callto:+2250564250219">
                                            <i className="fal fa-phone" /> +(225) 0564 25 0219
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://goo.gl/maps/5aDCxc5gVQBhgAPn6" target='_blank' rel="noreferrer">
                                            <i className="fal fa-map-marker-alt" /> Cocody, Abidjan
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © {years} SHOUZ. Tous droits réservés.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
