import React, { useState } from 'react';

function FaqHomeOne({ className }) {
    const [showQues, setQues] = useState(1);
    const openQuestion = (value) => {
        setQues(value);
    };
    return (
        <>
            <section className={`appie-faq-area pb-95 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Foire aux Questions</h3>
                                <p>Nous répondons aux questions d'ordre générale dans cette section.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp animated fadeIn faq-accrodion wow"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        onClick={() => openQuestion(1)}
                                        className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Combien de temps est dispnible le service de rechargement et retrait ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 1 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>Vous pouvez recharger votre compte ShouzPay ou faire des retraits 24h/24 et 7j/7.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(2)}
                                        className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Quelle est la différence entre un produit simple et un produit VIP ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 2 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>Un produit VIP a comme avantage un lien d'article visible par tout le monde qui peut-être partagé par n'importe qu'elle membre sur les réseaux sociaux.
                                                        <br />
                                                        Les images du produit peuvent être enregistré par n'importe quelle autre membre.
                                                        <br />
                                                        Un vendeur qui déclare un produit VIP bénéficie d'une boutique dans lequel tout ses produits VIP sont visibles par tous les autres membres.
                                                        <br />
                                                        Un produit déclaré VIP le reste à vie.
                                                        <br />
                                                        Les produits VIP sont présentés en premier lieu aux acheteurs avant les produits simples.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(3)}
                                        className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>À quel coût déclare t'on un produit VIP ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 3 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>2000 Frs CFA. On déclare un produit VIP lors de sa création, et quelque soit le produit on ne paye que 2000 Frs.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms">
                                <div
                                    className="accrodion-grp animated fadeIn faq-accrodion wow"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    
                                    <div
                                        onClick={() => openQuestion(4)}
                                        className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Concernant les évènements, comment je peux demander un remboursement de ticket en cas d'indisponibilité de ma part ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 4 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>Si vous avez acheter des tickets et que finalement vous êtes indisponible pour participer à l'évènement vous pouvez demander un remboursement de ticket. Mais cela doit se faire au moins 24h avant le debut de l'évènement. Aussi on peut annuler un ticket que quand il ne contient pas plus de 2 places.
                                                        <br />
                                                        Vous serez remboursé de 90% du prix du ticket car 5% et le ticket lui même retourne au promoteur d'évènement en guise de dédomagement et les autres 5% reviennent à SHOUZ. 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(5)}
                                        className={`accrodion ${showQues === 5 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Comment faire si mon téléphone est éteint avant la vérification des tickets d'évènement ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 5 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>Si pour une raison ou une autre vous êtes dans l'incapacité de présenter votre ticket pour un évènement, pas d'inquiétude, vous pouvez donner votre numéro SHOUZ, le décodeur pourra l'utiliser pour vérifier si vous avez acheté un ticket pour l'évènement. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(6)}
                                        className={`accrodion ${showQues === 6 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Moi je suis intéressé par la rubrique covoiturage, je veux savoir comment je dois procéder pour pouvoir créer mes voyages ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 6 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>Tout se fait directement dans l'application. Il vous suffira de compléter votre profil en envoyant les images de votre carte grise, carte de visite, d'assurance, de votre permis, de votre véhicule et une photo d'identité. Nous traitons votre demande en moins de 48h pour rendre votre compte eligible pour créer des voyages.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(7)}
                                        className={`accrodion ${showQues === 7 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>C'est ma première fois dans la rubrique covoiturage et j'aimerais savoir la procédure pour acheter les tickets de voyage ?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 7 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>Tout se fait directement dans l'application. Il vous suffira de compléter votre profil en envoyant juste les images de votre pièce d'identité(CNI/PASSPORT/PERMIS/ATTESTATION ou EXTRAIT DE NAISSANCE) puis une photo de vous attrapant ladite pièce.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="faq-text text-center pt-40">
                                <p>
                                    Vous avez d'autres questions ?{' '}
                                    <a href="mailto:shouz225@outlook.com">Écrivez-nous directement</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FaqHomeOne;
