import React, { useState } from 'react';
import FunFactThumb from '../../assets/images/fun-fact-thumb.png';
import PopupVideo from '../PopupVideo';

function FunFactHomeThree() {
    const [showVideo, setVideoValue] = useState(false);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/i9HhFSQXX1A?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <section className="appie-fun-fact-area pb-100" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-fun-fact-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="appie-fun-fact-content">
                                            <h3 className="title">Shouz, En plus d'économiser, vous gagnez de l'argent</h3>
                                            <p>Shouz est le département social de CLUB12, nous travaillons pour améliorer le cadre de vie social de la population.</p>
                                            <div className="row">
                                                <div className="col-sm-3 col-xs-6">
                                                    <div className="appie-fun-fact-item">
                                                        <h4 className="sub-title">
                                                            Actualités
                                                        </h4>
                                                        <span>Rubrique 1</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-xs-6">
                                                    <div className="appie-fun-fact-item">
                                                        <h4 className="sub-title">
                                                            Deals
                                                        </h4>
                                                        <span>Rubrique 2</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-xs-6">
                                                    <div className="appie-fun-fact-item">
                                                        <h4 className="sub-title">
                                                            Évènements
                                                        </h4>
                                                        <span>Rubrique 3</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 col-xs-6">
                                                    <div className="appie-fun-fact-item">
                                                        <h4 className="sub-title">
                                                            Covoiturages
                                                        </h4>
                                                        <span>Rubrique 4</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-fun-fact-play">
                                            <a
                                                onClick={(e) => handleShowVideo(e)}
                                                className="appie-video-popup"
                                                href="https://www.youtube.com/watch?v=i9HhFSQXX1A"
                                            >
                                                <i className="fas fa-play" />
                                            </a>
                                            <img src={FunFactThumb} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FunFactHomeThree;
