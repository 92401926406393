import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import logo from '../assets/images/logo.png';
function Drawer({ drawer, action }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (value) => {
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} className="logo icon-logo" alt="logo" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li
                                            onClick={(e) => handler('deals')}
                                            id="deals"
                                            className="menu-item-has-children active"
                                        >
                                            <a href="#serviceDeals">Deals</a>
                                        </li>
                                        <li
                                            onClick={(e) => handler('event')}
                                            id="event"
                                            className="menu-item-has-children active"
                                        >
                                            <a href="#eventService">Événements</a>
                                        </li>
                                        <li
                                            onClick={(e) => handler('covoiturage')}
                                            id="covoiturage"
                                            className="menu-item-has-children active"
                                        >
                                            <a href="#serviceTravel">Covoiturages</a>
                                        </li>
                                        <li
                                            onClick={(e) => handler('news')}
                                            id="news"
                                            className="menu-item-has-children active"
                                        >
                                            <a href="#actualiteIdent">Actualités</a>
                                        </li>
                                        <li
                                            onClick={(e) => handler('shouzpay')}
                                            id="shouzpay"
                                            className="menu-item-has-children active"
                                        >
                                            <a href="#fun-fact-area-shouzpay">ShouzPay</a>
                                        </li>
                                        <li>
                                            <Link to="/TikTok-Award">TikTok Award</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a href="https://www.linkedin.com/company/shouz225" target='_blank' rel="noreferrer">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/shouz225" target='_blank' rel="noreferrer">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/Shouz225" target='_blank' rel="noreferrer">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://t.me/shouz_community" target='_blank' rel="noreferrer">
                                                <i className="fab fa-telegram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/shouz_corporation/" target='_blank' rel="noreferrer">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="mailto:shouz225@outlook.com">
                                                <i className="fal fa-envelope" /> shouz225@outlook.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href="callto:+2250564250219">
                                                <i className="fal fa-phone" /> +(225) 0564 25 0219
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://goo.gl/maps/5aDCxc5gVQBhgAPn6" target='_blank' rel="noreferrer">
                                                <i className="fal fa-map-marker-alt" /> Cocody, Abidjan
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
