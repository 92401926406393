import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import videoSlideOne from '../../assets/images/createItem.png';
import videoSlideTwo from '../../assets/images/dealsPage.png';
import videoSlideThree from '../../assets/images/allInnerPage.png';
import videoSlideFour from '../../assets/images/chatApp.png';
import videoSlideFive from '../../assets/images/withdrawPage.png';
import videoSlideSix from '../../assets/images/settingPage.png';
import videoSlideSeven from '../../assets/images/profilPage.png';
import videoThumb from '../../assets/images/dash_without_bg.png';
import PopupVideo from '../PopupVideo';

function VideoPlayerHomeTwo({ className }) {
    const [showVideo, setshowVideoValue] = useState(false);
    const handleVideoShow = (e) => {
        e.preventDefault();
        setshowVideoValue(!showVideo);
    };
    const sliderRef = useRef();
    const settings = {
        autoplay: true,
        arrows: false,
        dots: false,
    };
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    handler={(e) => handleVideoShow(e)}
                    videoSrc="//www.youtube.com/embed/i9HhFSQXX1A?autoplay=1"
                />
            )}
            <section className={`appie-video-player-area pb-100 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="appie-video-player-item">
                                <div className="thumb">
                                    <img src={videoThumb} alt="" />
                                    <div className="video-popup">
                                        <a
                                            onClick={(e) => handleVideoShow(e)}
                                            role="button"
                                            href="#"
                                            className="appie-video-popup"
                                        >
                                            <i className="fas fa-play" />
                                        </a>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3 className="title">
                                        Vidéo publicitaire relatant nos services et actions.
                                    </h3>
                                    <p>Nous sommes disponible sur toutes les plateformes mobiles</p>
                                    <ul className='list-row'>
                                        <li>
                                                <a className="main-btn resize" href="https://apps.apple.com/app/shouz/id6444333797" target="_blank">App Store</a>
                                        </li>
                                        <li>
                                                <a className="main-btn resize" href="https://play.google.com/store/apps/details?id=com.shouz.app" target="_blank">Play Store</a>
                                        </li>
                                        <li>
                                                <a className="main-btn resize" href="https://appgallery.cloud.huawei.com/ag/n/app/C107065691?locale=fr_FR" target="_blank">App Gallery</a>
                                        </li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div
                                className="appie-video-player-slider"
                                style={{ position: 'relative' }}
                            >
                                <span
                                    onClick={sliderPrev}
                                    className="prev slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-left" />
                                </span>
                                <Slider ref={sliderRef} {...settings}>
                                    <div className="item">
                                        <img src={videoSlideOne} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={videoSlideTwo} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={videoSlideThree} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={videoSlideFour} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={videoSlideFive} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={videoSlideSix} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={videoSlideSeven} alt="" />
                                    </div>
                                </Slider>
                                <span
                                    onClick={sliderNext}
                                    className="next slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-right" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default VideoPlayerHomeTwo;
