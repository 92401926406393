import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyAx_OrUDTnoQjlZGTFl5k7LjBdJI-qt0M4",
    authDomain: "shouz-4aa71.firebaseapp.com",
    projectId: "shouz-4aa71",
    storageBucket: "shouz-4aa71.appspot.com",
    messagingSenderId: "270595904509",
    appId: "1:270595904509:web:26fa27e658adc7da36bf94",
    measurementId: "G-0P07CCFX8L"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}