import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';

import HomeSeven from './components/HomeSeven';
import ConfidentialContain from './components/HomeThree/ConfidentialContain';
import ConditionUsage from './components/HomeThree/ConditionUsage';
import AwardTikTok from './components/AwardTikTok';
import ExclusiveVoted from './components/AwardTikTok/ExclusiveVoted';

import { ParallaxProvider } from 'react-scroll-parallax';

function AppRoute() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <ParallaxProvider>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<HomeSeven />} />
                            <Route path="/TikTok-Award" element={<AwardTikTok />} />
                            <Route path="/TikTok-Award-Exclusive/:name" element={<ExclusiveVoted />} />
                            <Route path="/confidential-politic" element={<ConfidentialContain />} />
                            <Route path="/condition-general" element={<ConditionUsage />} />
                        </Routes>
                    </ScrollToTop>
                </Router>
            </div>
        </ParallaxProvider>
    );
}

export default AppRoute;
