//import { Etat } from "../Constants/Enum";
import { get, post } from "./network_utils";

export class ConsumeApi {
    base_url = "https://app.shouz.network";
    getAllTypeForPackageEventFromWebSite_url = this.base_url + "/event/getAllTypeForWebSite";
    getAllInfoTikTok_url = this.base_url + "/client/getInfoTikTok";
    getInfoForShouzWebsite_url = this.base_url + "/client/getInfoForShouzWebsite";
    getExclusifInfoTikTok_url = this.base_url + "/client/getExclusifInfoTikTok";
    createSaliorTikTokOrUpdateVoted_url = this.base_url + "/client/createSaliorTikTokOrUpdateVoted";

    constructor() {}

    async testPost(numero, password) {
        const body = {
            numero,
            password
        };
        return await post(this.getAllTypeForPackageEventFromWebSite_url, body);
    }

    async voted(idCategorie, actorId, idFacebook, email) {
        const body = {
            idCategorie,
            actorId,
            idFacebook,
            email
        };
        return await post(this.createSaliorTikTokOrUpdateVoted_url, body);
    }

    async getAllTypeForPackageEventFromWebSite() {
        return await get(`${this.getAllTypeForPackageEventFromWebSite_url}/XOF`);
    }

    async getAllInfoTikTokFromWebSite() {
        return await get(this.getAllInfoTikTok_url);
    }

    async getInfoForShouzWebsite() {
        return await get(this.getInfoForShouzWebsite_url);
    }

    async getInfoTikTokFromExclusive(name) {
        return await get(`${this.getExclusifInfoTikTok_url}/${name}`);
    }
}