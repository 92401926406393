import React, {useEffect, useState} from 'react';
import useToggle from '../../Hooks/useToggle';
import FaqHomeOne from '../HomeOne/FaqHomeOne';
import FeaturesHomeOne from '../HomeOne/FeaturesHomeOne';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import CounterArea from '../HomeTwo/CounterArea';
import ServicesHomeOne from '../HomeOne/ServicesHomeOne';
import TrafficHomeOne from '../HomeOne/TrafficHomeOne';


import HeroHomeSeven from './HeroHomeSeven';
import HeaderHomeSeven from './HeaderHomeSeven';

import Drawer from '../Drawer';
import BackToTop from '../BackToTop';
import PricingHomeTwo from '../HomeTwo/PricingHomeTwo';
import FeaturesHomeTwo from '../HomeTwo/FeaturesHomeTwo';
import VideoPlayerHomeTwo from '../HomeTwo/VideoPlayerHomeTwo';

import ServicesHomeThree from '../HomeThree/ServicesHomeThree';
import FunFactHomeThree from '../HomeThree/FunFactHomeThree';
import AboutHomeThree from '../HomeThree/AboutHomeThree';
import { ConsumeApi } from '../../servicesWorker/ConsumeApi';

function HomeSeven() {
    const [drawer, drawerAction] = useToggle(false);
    const [allPackageForEvent, setAllPackageForEvent] = useState([]);
    const [countInfo, setCountInfo] = useState({numberProduct:0, numberClient:0, numberEvent:0, numberTrravel:0});
    const consumeApi = new ConsumeApi();


    // Hooks Effet
    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const data = await consumeApi.getAllTypeForPackageEventFromWebSite();
        const item = await consumeApi.getInfoForShouzWebsite();
        setCountInfo(item);
        setAllPackageForEvent(data.result.listTypeAllEvents);
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeSeven action={drawerAction.toggle} />
            <HeroHomeSeven />
            <FeaturesHomeOne />
            <CounterArea numberProduct={countInfo.numberProduct} numberClient={countInfo.numberClient} numberEvent={countInfo.numberEvent} numberTrravel={countInfo.numberTrravel} />
            <ServicesHomeOne />
            <TrafficHomeOne />
            <PricingHomeTwo allPackageForEvent={allPackageForEvent} />
            <ServicesHomeThree />
            
            <FeaturesHomeTwo />
            <AboutHomeThree />

            <FunFactHomeThree />
            <FaqHomeOne className="pt-90" />
            <VideoPlayerHomeTwo className="pt-90" />
            
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeSeven;
