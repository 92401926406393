import React from 'react';
import aboutThumb from '../../assets/images/mobile_money.jpg';
import aboutThumb2 from '../../assets/images/cryptopayement.jpg';
import aboutThumb3 from '../../assets/images/visaPayement.jpg';
import percentage from '../../assets/images/percentage.png';
import smartphone from '../../assets/images/smartphone.png';
import cryptocurrency from '../../assets/images/cryptocurrency.png';
import credit_cards from '../../assets/images/credit_cards.png';

function AboutHomeThree() {
    return (
        <>
            <section className="appie-about-3-area pt-100 pb-100" id="fun-fact-area-shouzpay">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">Un membre, un compte ShouzPay</h3>
                                <p>Toutes transactions (achat et vente) se font directement dans l'application. Ainsi vous pouvez faire vos opérations avec différents services monétaires.</p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30">
                                        <div className="icon">
                                            <img src={smartphone} className="icon-25" alt="smartphone" />
                                        </div>
                                        <h5 className="title">Mobile Money</h5>
                                        <p>Wave, MTN Money, Orange Money et Moov Monney sont tous disponible</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30">
                                        <div className="icon">
                                            <img src={percentage} className="icon-25" alt="percentage" />
                                        </div>
                                        <h5 className="title">Frais</h5>
                                        <p>5% sur chaque rechargement (3% pour le réseau et 2% pour SHOUZ) et 300 Frs sur chaque retrait.</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-100 flex-column-reverse flex-lg-row">
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">ShouzPay travaille aussi avec la crypto-monnaie</h3>
                                <p>Nous ne pouvons pas rester en marge de cette avancée technologique qui vous est bénéfique.</p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30 item-3">
                                        <div className="icon">
                                            <img src={cryptocurrency} className="icon-25" alt="cryptocurrency"/>
                                        </div>
                                        <h5 className="title">Crypto-monnaie</h5>
                                        <p>Bitcoin & Ethereum sont disponible.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30 item-4">
                                        <div className="icon">
                                            <img src={percentage} className="icon-25" alt="percentage" />
                                        </div>
                                        <h5 className="title">Frais</h5>
                                        <p>2% sur chaque rechargement et 0% sur chaque retrait.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 text-right wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb2} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-100">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb3} alt="aboutThumb3" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title">
                                <h3 className="title">ShouzPay marche aussi avec carte de credit</h3>
                                <p>Nous avons pensé à tous, c'est pourquoi nous proposons des solutions optimales dans les plus moindres coûts.</p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service mb-30">
                                        <div className="icon">
                                            <img src={credit_cards}  className="icon-25" alt="credit_cards" />
                                        </div>
                                        <h5 className="title">Carte de credit</h5>
                                        <p>Retrait et rechargement sont possibles par visa et master carte.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="appie-traffic-service item-2 mb-30">
                                        <div className="icon">
                                            <img src={percentage} className="icon-25" alt="percentage" />
                                        </div>
                                        <h5 className="title">Frais</h5>
                                        <p>5% sur chaque rechargement et 300 Frs sur chaque retrait.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutHomeThree;
