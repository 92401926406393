import React, {useEffect, useState} from 'react';
import useToggle from '../../Hooks/useToggle';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {CloseCircleOutlined, LikeOutlined, SmileOutlined} from '@ant-design/icons';
import {  Carousel, Modal } from 'antd';

import HeaderHomeSeven from '../HomeSeven/HeaderHomeSeven';

import Drawer from '../Drawer';
import BackToTop from '../BackToTop';

import { ConsumeApi } from '../../servicesWorker/ConsumeApi';
import { notification } from 'antd';

function AwardTikTok({className}) {
    const [drawer, drawerAction] = useToggle(false);
    const [indexCategorie, changeIndexCategorie] = useState(0);
    const [itemVoted, choiceItemVoted] = useState([]);
    const [infoVoted, setInfoVoted] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewVoted, setViewVoted] = useState(false);
    const [votedByFacebookComponent, changeVotedByFacebookComponent] = useState(true);
    const consumeApi = new ConsumeApi();


    const responseFacebook = async (response) => {
        if(votedByFacebookComponent) {
            if(response.userID) {
                localStorage.setItem("userID", response.userID.toString());
                localStorage.setItem("email", response.email ?? response.name);

                notification.open({
                    message: 'Envoie du vote en cours...',
                    description:`Vous avez lancé un vote pour ${itemVoted[3]} dans la categorie ${itemVoted[2]}. Nous analysons avant de valider votre vote.`,
                    icon: (
                      <SmileOutlined
                        style={{
                          color: '#108ee9',
                        }}
                      />
                    ),
                    placement: "bottom"
                  });
                const data = await consumeApi.voted(itemVoted[0], itemVoted[1], response.userID, response.email ?? response.name);
                notification.destroy();
                if(data['etat'] === "found") {
                    notification.open({
                        message: 'Vote enregistré avec succès',
                        icon: (
                          <LikeOutlined
                            style={{
                              color: '#52c41a',
                            }}
                          />
                        ),
                        placement: "bottom"
                      });
                      await loadData();
                } else {
                    notification.open({
                        message: 'Echec',
                        description: data['error'],
                        icon: (
                          <CloseCircleOutlined
                            style={{
                              color: '#eb2f96',
                            }}
                          />
                        ),
                        placement: "bottom"
                      });
                }
                changeVotedByFacebookComponent(false);
            
                
            }
        } else {
            const userID =  localStorage.getItem("userID");
                const email = localStorage.getItem("email");
                notification.open({
                    message: 'Envoie du vote en cours...',
                    description:`Vous avez lancé un vote pour ${response.image} dans la categorie ${response.nameCategorie}. Nous analysons avant de valider votre vote.`,
                    icon: (
                      <SmileOutlined
                        style={{
                          color: '#108ee9',
                        }}
                      />
                    ),
                    placement: "bottom"
                  });
                const data = await consumeApi.voted(response.idCategorie, response.actor, userID, email);
                notification.destroy();
                if(data['etat'] === "found") {
                    notification.open({
                        message: 'Vote enregistré avec succès',
                        icon: (
                          <LikeOutlined
                            style={{
                              color: '#52c41a',
                            }}
                          />
                        ),
                        placement: "bottom"
                      });
                      await loadData();
                } else {
                    notification.open({
                        message: 'Echec',
                        description: data['error'],
                        icon: (
                          <CloseCircleOutlined
                            style={{
                              color: '#eb2f96',
                            }}
                          />
                        ),
                        placement: "bottom"
                      });
                }
        }
        displayPub();
      }

      const displayPub = () => {
        const publicité = localStorage.getItem("publicité") ?? "";
        if(publicité === "") {
          showModal();
          localStorage.setItem("publicité", new Date().getTime().toString());
        } else {
          const lastDate = parseInt(publicité, 10)/ 1000;
          if(new Date().getTime()/1000 - lastDate > 172800) {
          showModal();
          localStorage.setItem("publicité", new Date().getTime().toString());
          }
        }
      }
      const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };

    // Hooks Effet
    useEffect(() => {
        const userID = localStorage.getItem("userID") ?? "";
        const email = localStorage.getItem("email") ?? "";
        if(userID !== "" && email !== "") {
            changeVotedByFacebookComponent(false);
        }
        loadData();
    }, []);

    const loadData = async () => {
        const data = await consumeApi.getAllInfoTikTokFromWebSite();
        setInfoVoted(data);
    }

    return (
        <>
          <Modal title="Téléchargez l'application SHOUZ et gagnez le max de bonus." 
          open={isModalOpen} 
          onOk={handleOk} 
          onCancel={handleCancel}
          footer={[
            <a className="main-btn m-r-5" href="https://apps.apple.com/app/shouz/id6444333797" target="_blank" rel="noreferrer">
              App Store
           </a>,
            <a className="main-btn m-r-5" href="https://play.google.com/store/apps/details?id=com.shouz.app" target="_blank" rel="noreferrer">
                Google Play
            </a>,
            <a className="main-btn" href="https://appgallery.cloud.huawei.com/ag/n/app/C107065691?locale=fr_FR" target="_blank" rel="noreferrer">
                App Gallery
            </a>,
          ]}
          >
          <Carousel dotPosition="right" autoplay>
            <div>
              <img src="https://app.shouz.network/public/app1a.jpg" alt="Achète tout à ton prix, nous te livrons." />
            </div>
            <div>
            <img src="https://app.shouz.network/public/app2a.jpg" alt="Vends tout article déplaçable, nous t'offrons une boutique professionel." />
            </div>
            <div>
            <img src="https://app.shouz.network/public/app3a.jpg" alt="Achète tes tickets d'évènements par mobile money très tôt, et si tu trouves que tu ne seras plus disponible pour y participer, nous te remboursons." />
            </div>
            <div>
              <img src="https://app.shouz.network/public/app4a.jpg" alt="Crée ton évènement, vends tes tickets et faire le maximum de revenus gràce à nos outils." />
            </div>
            <div>
              <img src="https://app.shouz.network/public/app7a.jpg" alt="Crée ton évènement, vends tes tickets et faire le maximum de revenus gràce à nos outils." />
            </div>
          </Carousel>
          </Modal>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeSeven action={drawerAction.toggle} />
            <section className={`appie-project-3-area ${className}`}>
            <div className="appie-project-3-box d-block d-md-flex justify-content-between align-items-center wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms" onDoubleClick={(event)=> {
                    event.preventDefault();
                    setViewVoted(!viewVoted);
                  }}>
                <h4 className="title">
                    TikTok Award {infoVoted.length > 0 ? infoVoted[indexCategorie].nameCategorie.toLocaleUpperCase(): ''}
                </h4>
                                
            </div>
            </section>
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-7">
                            <div className="row">
                                {infoVoted.length > 0 ? infoVoted[indexCategorie].content.map(actor => (<div key={`actor${actor._id}`} className="col-lg-4">
                                    <div className="post-item-1">
                                        <img src={actor.name} alt={actor.image} className="img-tiktok" />
                                        <div className="b-post-details">
                                            <div className="bp-meta">

                                              <a href="#">
                                                  <i className="fal fa-users"></i>{actor.numberVoted} Voie(s)
                                              </a>
                                              </div>
                                            <h3>
                                                <a href="#">
                                                {actor.image.replaceAll('_', ' ')}
                                                </a>
                                            </h3>
                                            {/* {votedByFacebookComponent ? <FacebookLogin
                                                appId="5571286109622286"
                                                fields="name,email,picture"
                                                onClick={(event)=> {
                                                    choiceItemVoted([infoVoted[indexCategorie].idCategorie, actor._id, infoVoted[indexCategorie].nameCategorie.toLocaleUpperCase(), actor.image.replaceAll('_', ' ')])
                                                }}
                                                disableMobileRedirect={ true }
                                                isMobile={false}
                                                render={renderProps => (
                                                    <a className="read-more" onClick={renderProps.onClick}>
                                                        Voter <i className="fal fa-arrow-right"></i>
                                                    </a>
                                                  )}
                                                callback={responseFacebook} /> : <a className="read-more" onClick={(event)=> {
                                                    event.preventDefault();
                                                    
                                                    responseFacebook({idCategorie: infoVoted[indexCategorie].idCategorie, actor:  actor._id, nameCategorie: infoVoted[indexCategorie].nameCategorie.toLocaleUpperCase(), image:  actor.image.replaceAll('_', ' ')});
                                                }}>
                                                Voter <i className="fal fa-arrow-right"></i>
                                            </a>} */}
                                            
                                        </div>
                                    </div>
                                </div>)
                                ): ''}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5">
                            <div className="blog-sidebar">

                                <aside className="widget widget-categories">
                                    <h3 className="widget-title">Categories</h3>
                                    <ul>
                                        {infoVoted.map((value,index) => (
                                            <li key={`idCategorie${value.idCategorie}`}>
                                                <a href='#' onClick={()=> changeIndexCategorie(index)}>{value.nameCategorie.toLocaleUpperCase()}</a>
                                                <span>({value.content.length})</span>
                                            </li>)
                                            )
                                        }
                                        
                                    </ul>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default AwardTikTok;