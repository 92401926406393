import axios from 'axios';
import { Etat } from '../Constants/Enum';

const axiosInstance = axios.create();

export async function get(url) {
    return new Promise((next) => {
        axiosInstance.get(url)
        .then(response => {
            const json = response.data;
            next(json);
            }
        )
        .catch(error => {next({etat: Etat.FAILED, error})});
    });
}

export async function post(url, body) {
    return new Promise((next) => {
        axiosInstance.post(url, body)
        .then(response => {
            const json = response.data;
            next(json);
            }
        )
        .catch(error => {next({etat: Etat.FAILED, error})});
    });
}