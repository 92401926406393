import React from 'react';
import heroThumb2 from '../../assets/images/hero-shape-2.png';
import heroThumb8 from '../../assets/images/hero-thumb-8.png';
import appGallery from '../../assets/images/huawei.png';

function HeroHomeSeven() {
    return (
        <>
            <section className="appie-hero-area appie-hero-6-area appie-hero-7-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="appie-hero-content appie-hero-content-6 appie-hero-content-7">
                                <h1 className="appie-title">Actualité, E-commerce<br/>Évènementiel & Covoiturage</h1>
                                <p>SHOUZ, une solution optimale à moindre coût et disponible pour tous.</p>
                                <ul className='list-row-hero'>
                                    <li>
                                        <a href="https://apps.apple.com/app/shouz/id6444333797" target="_blank" rel="noreferrer">
                                            <i className="fab fa-apple"></i>
                                            <span>
                                                Disponible sur <span>App Store</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="https://play.google.com/store/apps/details?id=com.shouz.app" target="_blank" rel="noreferrer">
                                            <i className="fab fa-google-play"></i>
                                            <span>
                                                Disponible sur <span>Google Play</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="https://appgallery.cloud.huawei.com/ag/n/app/C107065691?locale=fr_FR" target="_blank" rel="noreferrer">
                                            <img src={appGallery} className="icon-store" alt="appGalery" />
                                            <span>
                                                Disponible sur <span>App Gallery</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                                <div className="info"></div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="appie-hero-thumb-6">
                                <div
                                    className="thumb wow animated fadeInUp text-center"
                                    data-wow-duration="1000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={heroThumb8} alt="" />
                                    <div className="back-image">
                                        <img src={heroThumb2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeSeven;
