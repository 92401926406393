import React from 'react';
import counterIconOne from '../../assets/images/icon/counter-icon-1.svg';
import counterIconTwo from '../../assets/images/sellProduct.png';
import counterIconThree from '../../assets/images/calendar.png';
import counterIconFour from '../../assets/images/destination.png';
import CounterUpCom from '../../lib/CounterUpCom';

function CounterArea({ style, numberProduct, numberClient, numberEvent, numberTrravel }) {
    return (
        <>
            <section className="appie-counter-area pt-90 pb-190" id="counter" style={style}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Shouz en chiffre</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-counter mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src={counterIconOne} alt="" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={numberClient} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p>Comptes actifs</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-counter mt-30 item-2 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src={counterIconTwo} alt="Produits" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={numberProduct} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p>Produits vendus & satisfaisants</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-counter mt-30 item-3 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src={counterIconThree} alt="Évènements" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={numberEvent} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p>Évènements créés</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-counter mt-30 item-4 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src={counterIconFour} alt="" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={numberTrravel} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p>Voyages effectués</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CounterArea;
