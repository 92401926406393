import React from 'react';
import iconOne from '../../assets/images/basPrice.png';
import iconTwo from '../../assets/images/identityCheck.png';
import iconThree from '../../assets/images/passenger.png';
import iconFour from '../../assets/images/easy_reserve.png';

function ServicesHomeThree() {
    return (
        <>
            <section className="appie-service-area appie-service-3-area pt-20 pb-85" id="serviceTravel">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Voyagez chez SHOUZ !</h3>
                                <ol className='forced pl-10'>
                                    <li>
                                        <p>Si vous voulez acheter des tickets de voyage, nous vous offrons le meilleur cadre.</p>
                                    </li>
                                    <li>
                                        <p>
                                        Si vous êtes véhiculé vous pouvez gagner de l'argent en vendant des places pour vos différents voyages.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={iconOne} className="icon-40" alt="IconOne" />
                                </div>
                                <h4 className="appie-title">Économique</h4>
                                <p>Les prix des tickets sont les plus bas du marché du transport. Nous vous faisons économiser.</p>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className=" appie-single-service appie-single-services-3 text-center mt-30 item-2 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <img src={iconTwo} className="icon-40" alt="IconTwo" />
                                </div>
                                <h4 className="appie-title">Sécurisé</h4>
                                <p>Nos chauffeurs sont examinés minutieusement par les autorités du transport, de même que les passagers.</p>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-service appie-single-services-3 text-center mt-30 item-3 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src={iconThree} className="icon-40" alt="IconThree" />
                                </div>
                                <h4 className="appie-title">Confortable</h4>
                                <p>Vous pouvez choisir le type de véhicule mais aussi la place que vous voulez pour le voyage.</p>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-single-service appie-single-services-3 text-center mt-30 item-4 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <div className="icon">
                                    <img src={iconFour} className="icon-40" alt="IconFour" />
                                </div>
                                <h4 className="appie-title">Accessible</h4>
                                <p>Vous pouvez vous procurer vos tickets à tout moment peut importe le lieu. Et même pour des voyages à venir.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeThree;
