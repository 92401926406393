import React from 'react';
import {numberWithCommas} from '../../helpers/functions'
function PricingHomeTwo(props) {
    
    return (
        <>
            <section className="appie-pricing-2-area pb-20 pt-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Liste de nos packages</h3>
                                <p>Cette liste est pour ceux qui désirent créer et vendre des tickets.<br />Payable à chaque création d'évènement</p>
                                <div className="appie-pricing-tab-btn">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className={`tab-pane fade active show`}
                                    id="pills-home"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                >
                                    <div className="row justify-content-center">
                                        {props.allPackageForEvent.map((value, index) => (
                                           <div key={`packageItem${index}`} className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xs-12 pb-35">
                                           <div className="pricing-one__single pricing-one__single_2 active wow animated fadeInUp">
                                               <div className="pricig-heading">
                                                   <h6>{value.title.replace('_', ' ')}</h6>
                                                   <div className="price-range">
                                                       <span>{numberWithCommas(value.priceLocalCurrencies)}</span>
                                                       <p>Frs CFA</p>
                                                   </div>
                                               </div>
                                               <div className="pricig-body">
                                                   <ul>
                                                       {value.describe.map((describe, newIndex)=>(
                                                           <li key={`describePackage${newIndex}`}>
                                                                <i className="fal fa-check" /> {describe}
                                                            </li>
                                                       ))}
                                                       
                                                       <li>
                                                           <i className="fal fa-check" /> Max place : {value.maxPlace}
                                                       </li>
                                                   </ul>
                                                   
                                                   
                                               </div>
                                           </div>
                                       </div> 
                                        ))}
                                        
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PricingHomeTwo;
