import React from 'react';
import thumb from '../../assets/images/eventDisplay.png';
import thumbMobile from '../../assets/images/event_illustre_for_mobile.png';
import { Parallax } from 'react-scroll-parallax';

function TrafficHomeOne() {

    return (
        <section className="appie-traffic-area" id='eventService'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 pt-15">
                        <div className="appie-traffic-title">
                            <h3 className="title">Notre rubrique Événement est faite pour tous.</h3>
                            <p>Vendre ou acheter des tickets n'a jamais été aussi simple. Nous mettons plusieurs fonctionnalités à la disposition de tous afin de créer l'environnement le plus conviviale</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Création d'événement</h5>
                                    <p>Pour tout type d'événement. Des gratuits de moins de 100 places jusqu'aux payants de plus de 90000 places.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-2 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Ticket Sécurisé</h5>
                                    <p>Ce sont des QR-Code uniques décodables par le créateur d'évènement ou des tierces personnes qu'il aurait choisi.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-3 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Statistique des ventes</h5>
                                    <p>Nous donnons les statistiques de vos ventes afin que vous puissiez faire des meilleurs strategies pour vos prochains événements.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-4 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Partage de ticket</h5>
                                    <p>Un acheteur de ticket a la possibilité de partager son ou ses tickets à différents autres membres dans l'application.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-5 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Remboursement de ticket</h5>
                                    <p>Un acheteur peut annuler son ticket en cas d'indisponibilité de sa part. Il sera remboursé à 90% de son ticket.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-6 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Accessibilité</h5>
                                    <p>Tout membre peut créer un évènement et vendre ses tickets. Il suffit de choisir le package qui convient le plus à ses besoins.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-7 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Bonus</h5>
                                    <p>En plus de la gestion de ticket nous vous aidons dans la publicité de votre évènement et nous prenons aucun frais pour ce service.</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-12 only-mobile">
                        <div className="traffic-thumb-only-mobile" >
                                <img
                                    className="wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                    src={thumbMobile}
                                    alt=""
                                    
                                />
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb" >
                <Parallax speed={-15}>
                    <img
                        className="wow animated fadeInRight"
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                        src={thumb}
                        alt=""
                        
                    />
                </Parallax>
            </div>
        </section>
    );
}

export default TrafficHomeOne;
