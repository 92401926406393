import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'antd/dist/reset.css';
import './assets/css/bootstrap.min.css';
import './assets/css/custom-animated.css';
import './assets/css/default.css';
import './assets/css/font-awesome.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/main.css';
import './assets/css/style.css';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('app');

const root = createRoot(container);
root.render(<App/>);

reportWebVitals();
