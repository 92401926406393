import React from 'react';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';

function ConditionUsage({ className }) {
    return (
        <>
            <section className={`appie-project-3-area ${className} `}>
            <div
                                className="
                appie-project-3-box
                d-block d-md-flex
                justify-content-between
                align-items-center
                wow
                animated
                fadeInUp
              "
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <h4 className="title">
                                    Conditions d'utilisations</h4>
                                
                            </div>
            </section>
            <section className="contentText mt-140 mb-50">
                <div className="container">
                    <h2>Contenu</h2>
                    <ul className="list_cgu">
                        <li>Introduction</li>
                        <li>Inscription et compte</li>
                        <li>Conditions générales de vente</li>
                        <li>Conditions générales d'achat</li>
                        <li>Retours et remboursements</li>
                        <li>Création et promotion d'évènement</li>
                        <li>Vérification de ticket</li>
                        <li>Partage de ticket</li>
                        <li>Demande conducteur</li>
                        <li>Demande passager</li>
                        <li>Réchargement et retrait</li>
                        <li>Utilisation du site Web et des applications mobiles</li>
                        <li>Propriété intellectuelle (Copyright) et marques déposées</li>
                        <li>Protection des données</li>
                        <li>Rôle de SHOUZ en tant que Marketplace</li>
                        <li>Rôle de SHOUZ en tant que Gestionnaire d'évènement</li>
                        <li>Rôle de SHOUZ en tant dans le covoiturage extra-ville</li>
                        <li>Rôle de SHOUZ en tant dans l'actualité</li>
                        <li>Limitations et exclusions de responsabilité</li>
                        <li>Violation des présentes conditions générales</li>
                        <li>Intégralité de l'accord</li>
                        <li>Hiérarchie</li>
                        <li>Droits des tiers</li>
                        <li>Loi applicable et juridiction</li>
                        <li>Les coordonnées de notre société</li>
                    </ul>
                <h2>Introduction</h2>

                <p></p>

                <br /><h4>2.1. Les informations que vous nous transmettez directement</h4>
                <p>
                    En utilisant notre Plateforme, vous êtes amenés à nous transmettre des informations, dont certaines sont de nature à vous identifier (« Données Personnelles ») ou à vous localiser par le biais des services informatiques (« Localisation que pendant l'utilisation »). C’est notamment le cas lorsque vous remplissez des formulaires (comme par exemple le formulaire d’inscription, la demande du conducteur, la demande du passager), lorsque vous crée vos articles et/ou évènements, offres promotionnelles, études ou sondages, lorsque vous nous contactez – que ce soit par téléphone, email ou tout autre moyen de communication – ou lorsque vous nous faites part d’un problème concernant l’utilisation de notre Plateforme.
                    <br />
                    Ces informations contiennent notamment les données suivantes :
                </p>
                <br /><h5>2.1.1. Les données nécessaires à l’inscription au service que nous fournissons sur notre Plateforme ou à l’accès à tout autre service fournis par nous. Ces données sont notamment vos nom et prénom, un numero de téléphone, quelques uns de vos sujets de préférences, lorsque vous créez un compte, un mot de passe. Ces informations sont obligatoires. A défaut, Shouz ne sera pas en mesure de vous fournir les services proposés par notre Plateforme ou, le cas échéant, vous ne serez pas autorisés à créer de compte sur notre Plateforme.</h5>
                <br /><h5>2.1.2. Une photographie pour votre profil</h5>
                <br /><h5>2.1.3. Pour la creation d'un évènement, en outre des informations relatives à l'évènement, nous vous demandons une adresse e-mail pour vous notifier de l'avancement de votre évènement</h5>
                <br /><h5>2.1.4. Pour la demande du passager, nous vous demandons des photographies d'une pièce d'identité et une phhotographie de vous attrapant cette pièce d'identité</h5>
                <br /><h5>2.1.5. Pour la demande du conducteur, nous vous demandons des photographies du permis de conduire, de l'assurance du vehicule, de la carte grise, de la carte de visite technique, du vehicule en outre de vos préférence de voyage, du nombre de places que peut contenir votre vehicule hors mis celle du conducteur, et d'une photo portrait à l'instant de la démande.</h5>
                <br /><h5>2.1.6. Une copie de tous les échanges écrits oraux intervenus entre vous et Shouz; nous sommes susceptibles d’enregistrer et/ou écouter les conversations téléphoniques entre vous et Shouz (par exemple, avec les agents de notre service Covoiturages). Nous vous donnons les moyens de vous opposer à ce traitement en début de conversation.</h5>
                <br /><h5>2.1.7. Une copie de l’ensemble des réservations, achats, ventes ou des publications de voyage et évènement effectués sur notre Plateforme.</h5>
                <br /><h5>2.1.8. Le détail des opérations financières ou comptables effectuées sur notre Plateforme ou par tout autre moyen, contenant notamment les informations relatives à votre carte de paiement, vos données de transactions monétaires.</h5>
                <br /><h5>2.1.9. Le détail de vos visites sur notre Plateforme et des contenus auxquels vous avez accédé.</h5>
                <br /><h5>2.1.10. Dans les actualités et covoiturages, vos commentaires et avis que vous avez laissés pour donner votre point de vu sur un article ou pour évaluer un voyage effectué par le biais de notre Plateforme, ces informations étant notamment susceptibles d’être utilisées dans le cadre de recherches et d’analyses du comportement utilisateur.</h5>
                <br /><h5>2.1.11. Les données que nous pouvons vous demander de fournir lorsque vous nous signalez un problème relatif à notre Plateforme ou à nos services, comme par exemple l’objet de votre demande d’assistance.</h5>
                <br /><h5>2.1.12. Les données liées à votre localisation lorsque vous avez accepté que nous collections et traitions ces données afin que nous puissions répondre à votre recherche de trajets proches de vous.</h5>
                <br />
                <br /><h4>2.2. Les données que nous recueillons automatiquement</h4>
                <br />
                <br /><h5>2.2.1. Lors de chacune de vos visites, nous sommes susceptibles de recueillir, conformément à la législation applicable et avec votre accord, le cas échéant, des informations relatives aux appareils sur lesquels vous utilisez nos services ou aux réseaux depuis lesquels vous accédez à nos services, tels que notamment vos adresses IP, données de connexion, systèmes et plateformes d’exploitation, votre dévise locale, le contenu auquel vous accédez ou que vous consultez, les termes de recherches utilisés, les erreurs de téléchargement, la durée de consultation de certaines pages, l’identifiant publicitaire de votre appareil, les interactions avec la page ainsi que tout numéro du téléphone utilisé pour nous contacter. Parmi les technologies utilisées pour recueillir ces informations, nous avons notamment recours aux cookies (pour en savoir plus à ce sujet, veuillez vous référer à notre Politique en matière de cookies).</h5>
                <br /><h5>2.2.2. Nous recueillons également des informations sur votre utilisation de notre Plateforme (telles que le nombre d’annonces publiées, votre taux de réponse aux messages, votre date d’inscription, votre moyenne d’avis reçus, etc.) qui sont susceptibles d’être affichées sur votre profil public, lorsque vous en avez un.</h5>
                <br /><h5>2.2.3. Nous recueillons des informations techniques relatives au modèle de votre véhicule lorsque vous nous transmettez votre numéro de plaque d’immatriculation afin de faciliter le remplissage du formulaire décrivant votre véhicule. Ces informations sont recueillies en interrogeant la base de données produite par les autorités téritoriales.</h5>
                <br /><h5>2.2.4. Enfin, nous recueillons des informations vous concernant de la part de nos partenaires assureurs ou intermédiaires en assurance dans le cadre de vos demandes d’éligibilité aux programmes d’assurances ou votre souscription aux contrats d’assurance proposés lorsque nous agissons en tant que mandataire d’assurance.</h5>
                <br />
                <br /><h4>2.3. Durée de conservation de vos données</h4>
                <br />
                <br /><h5>2.3.1. Vos Données Personnelles sont archivées à l’issue des périodes suivantes: </h5>
                <ul>
                    <li><p>5 ans après votre dernière utilisation de notre Plateforme, si vous n’avez pas fermé votre compte.</p></li>
                    <li><p>30 jours après la fermeture de votre compte, sauf si vous avez reçu un avis négatif ou un signalement, auquel cas vos données sont conservées pendant 2 ans suivant la fermeture de votre compte.</p></li>
                </ul>
                <br /><h5>2.3.2. Dans l’hypothèse où votre compte a été suspendu ou bloqué, nous conservons vos données pour une durée de 2 ou 10 ans selon la gravité de la violation à compter de la suspension afin d’éviter tout contournement de votre part des règles en vigueur sur notre Plateforme.</h5>
                <br /><h5>2.3.3. Ensuite, nous pouvons conserver en archivage certaines de vos Données Personnelles pour une durée n’excédant pas cinq (5) ans afin de respecter nos obligations légales et pour la gestion d’éventuels litiges.</h5>
                <br />
                <br />
                <h2>3. Comment utilisons-nous les données que nous recueillons ?</h2>
                <br />
                <p>Nous utilisons les données que nous recueillons afin de:</p>
                <br />

                <br /><h4>3.1  Exécuter les contrats conclus entre vous et nous ou entre nous et nos partenaires commerciaux et vous fournir les informations et services demandés; Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives.</h4>
                <br /><h4>3.2 Vous envoyer des renseignements sur nos services par e-mail, SMS ou tout autre moyen de communication; Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives ou realisé avec votre consentement.</h4>
                <br /><h4>3.3 Percevoir vos paiements ou vous transmettre les sommes collectées pour votre compte ;	Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives.</h4>
                <br /><h4>3.4 Vous permettre de personnaliser votre profil sur notre Plateforme ;	Ce traitement est réalisé sur la base de votre consentement.</h4>
                <br /><h4>3.5 Vous permettre de communiquer et d’échanger des services avec les autres membres de la plateforme.</h4>
                <br /><h4>3.6 Vous donner accès et vous permettre de communiquer avec notre service Administratif; Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives, réalisé avec votre consentement nécessaire à la constatation, l’exercice ou la défense d’un droit en justice.</h4>
                <br /><h4>3.7 Améliorer notre service administratif et former nos conseillers clientèle par l’enregistrement de vos conversations téléphoniques avec nous ;	Ce traitement est fondé sur notre intérêt légitime (vous fournir un support client de qualité/améliorer notre support client).</h4>
                <br /><h4>3.8 Nous assurer du respect de (i) la législation applicable, (ii) nos Conditions Générales d’Utilisation, et (iii) notre Politique de Confidentialité. En cas de manquements de votre part, nous pourrons être amenés à suspendre votre compte sur notre Plateforme. A ces fins, nous pouvons avoir recours à des technologies d’analyse automatique de votre utilisation de la Plateforme, y compris le contenu de vos annonces, afin de détecter tout comportement qui pourrait constituer un manquement de votre part ;	Ce traitement est (i) nécessaire à l’exécution de nos obligations contractuelles respectives, (ii) réalisé avec votre consentement ou (iii) nécessaire à la constatation, l’exercice ou la défense d’un droit en justice.</h4>
                <br /><h4>3.9 Vous envoyer, conformément aux dispositions légales applicables et avec votre accord lorsque la législation l’exige, des messages marketing, publicitaires et promotionnels et des informations relatives à l’utilisation de nos services, aux modalités de réservation, ou vous suggérer et vous conseiller des biens ou des services susceptibles de vous intéresser. Nous sommes également susceptibles d’utiliser vos données pour vous adresser des messages publicitaires susceptibles de vous intéresser sur les plateformes de réseaux sociaux ou sites de tiers. Si vous souhaitez davantage d’informations à ce sujet, nous vous invitons à prendre connaissance des documents contractuels de ces plateformes ou sites ;	Ce traitement est fondé sur votre consentement lorsque la législation l’exige ou notre intérêt légitime (vous fournir des informations pertinentes).</h4>
                <br /><h4>3.10 Afin de vous informer des modifications apportées à nos services ;	Ce traitement est nécessaire à (i) l’exécution de nos obligations contractuelles respectives, (ii) au respect de nos obligations légales ou (iii) à la constatation, l’exercice ou la défense d’un droit en justice.</h4>
                <br /><h4>3.11 Vérifier les informations contenues dans votre passeport, permis de conduire, carte d’identité ou tout autre document d’identité que nous aurions collecté lors de votre inscription ou votre utilisation de notre Plateforme ;	Ce traitement est (i) fondé sur votre consentement, (ii) nécessaire au respect de nos obligations légales ou (iii) nécessaire à la constatation, l’exercice ou la défense d’un droit en justice.</h4>
                <br /><h4>3.12 Gérer notre Plateforme et effectuer des opérations techniques internes dans le cadre de résolution de problèmes, analyse de données, de tests, recherches, analyses, études et sondages ;	Ce traitement est fondé sur notre intérêt légitime (assurer la sécurité de notre Plateforme et en améliorer leurs caractéristiques).</h4>
                <br /><h4>3.13 améliorer et optimiser notre Plateforme, notamment pour nous assurer que l’affichage de nos contenus est adapté à votre appareil ;	Ce traitement est fondé sur notre intérêt légitime (vous fournir des contenus pertinents).</h4>
                <br />
                <h2>4. Qui sont les destinataires des informations que nous recueillons et pourquoi leur transmettons nous ces informations ?</h2>
                <br />
                <br /><h4>4.1. Dans le cadre de l’utilisation de nos services, certaines de vos informations sont transmises aux autres membres de notre communauté ou opérateurs sur notre Plateforme par l’intermédiaire de votre profil public ou dans le cadre du processus de réservation.</h4>
                <br /><h4>4.2. Nous sommes également susceptibles de partager des informations vous concernant, notamment des Données Personnelles, avec d’autres entités du groupe CLUB12 dans le cadre prévu par la présente Politique de Confidentialité.</h4>
                <br /><h4>4.4. Nous ne partageons vos données avec les tiers mentionnés au 4.3. ci-dessus que dans les cas suivants :</h4>
                <br /><h5>4.4.1. Lorsque nous faisons appel à un prestataire de services dans le cadre de l’exécution de tout contrat conclu entre vous et nous ou afin de fournir ou améliorer nos services (par exemple dans le cadre des paiements effectués via notre Plateforme).</h5>
                <br /><h5>4.4.2. Dans le cadre du processus de réservation, nous sommes amenés, afin de fournir les services demandés, à afficher sur notre Plateforme ou à transmettre à un autre membre certaines de vos Données Personnelles telles que vos prénom, photographie, numéro de téléphone portable ou adresse e-mail.</h5>
                <br /><h5>4.4.3. Nous publions les avis que vous rédigez dans le cadre de notre système d’avis sur notre Plateforme. Ces avis, qui contiennent votre prénom et la première lettre de votre nom ainsi que votre photographie sont visibles par tous les visiteurs de notre Plateforme.</h5>
                <br />
                <br /><h4>4.5. Conformément à la législation applicable et avec votre consentement lorsqu’il est requis, nous pouvons agréger des données qui vous concernent et que nous recevons ou envoyons à nos partenaires commerciaux, notamment tout ou partie de vos Données Personnelles et les informations collectées par l’intermédiaire de cookies. Ces informations agrégées ne seront utilisées que pour les finalités décrites ci-dessus.</h4>
                <br /><h4>4.5. Conformément à la législation applicable et avec votre consentement lorsqu’il est requis, nous pouvons agréger des données qui vous concernent et que nous recevons ou envoyons à nos partenaires commerciaux, notamment tout ou partie de vos Données Personnelles et les informations collectées par l’intermédiaire de cookies. Ces informations agrégées ne seront utilisées que pour les finalités décrites ci-dessus.</h4>
                <br /><h4>4.6. Nous attirons votre attention sur le fait que si vous décidez de nous laisser accéder à certaines de vos informations, notamment à vos Données Personnelles, par l’intermédiaire de services de connexion mis à disposition par nos partenaires commerciaux, leurs politiques de confidentialité vous est également opposable. Nous n’avons aucun contrôle sur la collecte ou le traitement de vos données mis en œuvre par nos partenaires commerciaux sur leur propre plateforme.</h4>
                <br />
                <br />
                <h2>5. Comment utilisons-nous et modérons nous vos messages ?</h2>
                <br />
                <br /><h4>5.1. Nous pouvons prendre connaissance des messages que vous échangez avec d’autres membres de notre communauté via notre Plateforme notamment à des fins de prévention des fraudes, d’amélioration de nos services, d’assistance utilisateur, de vérification du respect par nos membres des contrats conclus avec nous et notamment de nos Conditions Générales d’Utilisation. Par exemple, afin d’éviter qu’un membre ne contourne notre système de réservation en ligne, nous nous autorisons à parcourir et analyser les messages échangés sur notre Plateforme afin de s’assurer qu’ils ne contiennent aucune coordonnée ou référence à d’autres sites internet et, le cas échéant, cela peut donner lieu à un blocage de ces messages ou filtrage de tout ou partie de ces messages.</h4>
                <br /><h4>5.2. Nous ne prenons jamais connaissance de vos communications avec d’autres membres de notre communauté à des fins promotionnelles ou de ciblage publicitaire. Lorsque cela est possible, nous avons recours à des systèmes automatisés pour procéder à la modération des messages transmis entre les membres via notre Plateforme, sans qu’aucune décision à portée individuelle ne soit prise.</h4>
                <br />
                <h2>6. Publicité ciblée, e-mails et SMS que nous vous envoyons</h2>
                <br />
                <p>Conformément à la législation applicable et avec votre consentement lorsqu’il est requis, nous pourrons utiliser les données que vous nous fournissez sur notre Plateforme à des fins de prospection commerciale (par exemple pour (i) vous adresser nos newsletters, (ii) vous envoyer des invitations à nos événements ou toute autre communication susceptible de vous intéresser et (iii) afficher des publicités ciblées sur les plateformes de médias sociaux ou sites tiers) selon votre profil et en fonction de vos centres d’intérêts.
                    <br />
                    En ce qui concerne la publicité ciblée :
                </p>
                <ul>
                    <li><p>Sur les plateformes de réseaux sociaux (par exemple Facebook): vous pouvez vous opposer à tout moment à ce traitement en configurant les paramètres relatifs à la publicité de votre compte directement sur la plateforme dudit réseau social ou en nous contactant selon les modalités décrites à l’article 13 ci-dessous.</p></li>
                    <li><p>Sur des sites tiers: vous pouvez vous reporter à notre Politique en matière de cookies pour comprendre comment retirer votre consentement.</p></li>
                </ul>
                <br />
                <h2>7. Vos données sont-elles transférées, comment et où ?</h2>
                <br />
                <p>En règle générale, nous conservons vos Données Personnelles au sein de  l’autorité ivoirienne.
                    <br />
                    Sur simple demande formulée à notre Délégué à la Protection des Données (dataprotection@shouz.network), nous pouvons vous fournir davantage d’informations quant à ces garanties (notamment les clauses contractuelles types du service de régularisation).
                </p>
                <br />
                <br />
                <h2>8. Quels sont vos droits sur vos données personnelles ?</h2>
                <br />
                <br /><h4>8.1. Vous disposez du droit de recevoir une copie de vos Données Personnelles en notre possession (« droit d’accès »).</h4>
                <br /><h4>8.2. Vous pouvez également demander l’effacement de vos Données Personnelles ainsi que la rectification des Données Personnelles erronées ou obsolètes (“droit d’effacement et droit de rectification”). Veuillez noter que nous sommes susceptibles de conserver certaines informations vous concernant lorsque la loi nous l’impose ou lorsque nous avons un motif légitime de le faire. C’est par exemple le cas, si nous estimons que vous avez commis une fraude ou violé nos Conditions Générales d’Utilisation et que nous souhaitons éviter que vous contourniez les règles applicables à notre communauté.</h4>
                <br /><h4>8.3. Vous disposez également du droit de vous opposer à tout moment pour des raisons tenant à votre situation particulière, (i) au traitement de vos Données Personnelles à des fins de marketing direct ou (ii) à d’autres traitements effectués sur le fondement de notre intérêt légitime (“droit d’opposition”).</h4>
                <br /><h4>8.4. Vous disposez du droit de limiter les traitements effectués sur vos Données Personnelles (“droit à la limitation”). Veuillez noter que ce droit ne s’applique que si (i) vous contestez l’exactitude de vos Données Personnelles pendant la durée nous permettant de vérifier l’exactitude de ces dernières; (ii) en cas de traitement illicite de notre part et que vous exigez une limitation de leur utilisation plutôt qu’un effacement, (iii) nous n’avons plus besoin des données à caractère personnel aux fins du traitement mais celles-ci vous sont encore nécessaires pour la constatation, l’exercice ou la défense de droits en justice; iv) en cas d’exercice de votre droit d’opposition pendant la durée de vérification portant sur le point de savoir si les motifs légitimes que nous poursuivons prévalent les votre.</h4>
                <br /><h4>8.5. Vous disposez également du droit à la portabilité de vos données, c’est-à-dire au droit de recevoir les Données Personnelles que vous nous avez fournis dans un format structuré, couramment utilisé et lisible par la machine et le droit de transmettre ces données à un autre responsable du traitement (« droit à la portabilité »).</h4>
                <br /><h4>8.6. Vous disposez du droit d’introduire une réclamation auprès de l’autorité de contrôle compétente ou d’obtenir réparation auprès des tribunaux compétents si vous considérez nous n’avons pas respecté vos droits.</h4>
                <br /><h4>8.7. Vous disposez également du droit de définir des directives relatives au sort de vos Données Personnelles après votre mort.</h4>
                <br /><h4>8.8. Pour exercer ces droits, vous pouvez contacter notre Délégué à la Protection des Données Personnelles selon les modalités définies à l’article 13 ci-dessous.</h4>
                <br />
                <br />
                <h2>9. Confidentialité de votre mot de passe</h2>
                <br />
                <p>
                Vous êtes responsable de la confidentialité du mot de passe que vous avez choisi pour accéder à votre compte sur notre Plateforme.
                <br />
                Vous vous engagez à conserver ce mot de passe secret et à ne le communiquer à personne.
                </p>
                <br />
                <br />
                <h2>10. Liens vers d’autres sites internet et réseaux sociaux</h2>
                <br />
                <p>Notre Plateforme peut occasionnellement contenir des liens vers les sites internet de nos partenaires ou de sociétés tierces. Veuillez noter que ces sites internet ont leur propre politique de confidentialité et que nous déclinons toute responsabilité quant à l’utilisation faite par ces sites des informations collectées lorsque vous cliquez sur ces liens. Nous vous invitons à prendre connaissance de politiques de confidentialité de ces sites avant de leur transmettre vos Données Personnelles.</p>
                <br />
                <br />
                <h2>11. Modification de notre politique de confidentialité</h2>
                <br />
                <p>Nous pouvons être amenés à modifier occasionnellement la présente Politique de Confidentialité. Lorsque cela est nécessaire, nous vous en informerons et/ou solliciterons votre accord. Nous vous conseillons de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications ou mises à jour apportées à notre Politique de Confidentialité.</p>
                <br />
                <br />
                <h2>13. Contact</h2>
                <br />
                <p>Pour toute question relative à la présente Politique de Confidentialité ou pour toute demande relative à vos Données Personnelles, vous pouvez nous contacter en adressant un email à notre délégué à la protection des données à l’adresse shouz225@outlook.com</p>
                </div>
            </section>
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default ConditionUsage;
